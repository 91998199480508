// Float content
.fl {float: left;}
.fr {float: right;}

// block float
ul.fbox,
ol.fbox {
	margin-left: 0;
}

ul.fbox li,
ol.fbox li {
	display: inline;
	list-style: none;
	float: left;
}
