// Base
//
.table {
	width: 100%;
	border-style: solid;
	border-width: 1px 1px 0 0;
	border-color: $border-color-base;
	@include breakpoint(md) {
		border-width: 1px;
	}
	table-layout: fixed;
	th,
	td {
		border-style: solid;
		border-width: 0 0 1px 1px;
		border-color: $border-color-base;
		vertical-align: top;
		text-align: left;
		line-height: 1.6;
		@include breakpoint(md) {
			border-width: 1px;
		}
	}
	thead th {
		background: $gray-lightest;
	}
	tbody th {
		background: $gray-lightest;
		font-weight: normal;
		text-align: left;
	}
}

// Table Thin
//
.table_thin {
	thead th {
		padding: .8rem 0;
		font-size: 1.2rem;
	}
	tbody th,
	tbody td {
		padding: 1rem 1em;
	}
}

// Table Regular
//
.table_regular {
	thead th {
		padding: 1.5rem 0;
	}
	tbody th,
	tbody td {
		display: block;
		padding: 1.5rem 1em 1.3rem;
		@include breakpoint(md) {
			display: table-cell;
		}
	}
}

// Table Heaby
//
.table_heavy {
	thead th {
		padding: 1.5rem 0;
		@include breakpoint(md) {
			padding: 2rem 0;
		}
	}
	tbody th,
	tbody td {
		display: block;
		padding: 1.5rem 1em 1.3rem;
		@include breakpoint(md) {
			padding: 2rem 1.5em 1.8rem;
			display: table-cell;
		}
	}
}

// Table header
.table_th_sm {
	@include breakpoint(md) {
		width: 10em;
	}
}
.table_th_md {
	@include breakpoint(md) {
		width: 15em;
	}
}
.table_th_lg {
	@include breakpoint(md) {
		width: 20em;
	}
}

/* Cell Width */

.table .w5 {width: 5% !important;}
.table .w10 {width: 10% !important;}
.table .w15 {width: 15% !important;}
.table .w20 {width: 20% !important;}
.table .w25 {width: 25% !important;}
.table .w30 {width: 30% !important;}
.table .w33 {width: 33% !important;}
.table .w35 {width: 35% !important;}
.table .w40 {width: 40% !important;}
.table .w45 {width: 45% !important;}
.table .w50 {width: 50% !important;}
.table .w55 {width: 55% !important;}
.table .w60 {width: 60% !important;}
.table .w65 {width: 65% !important;}
.table .w70 {width: 70% !important;}
.table .w75 {width: 75% !important;}
.table .w80 {width: 80% !important;}
.table .w85 {width: 85% !important;}
.table .w90 {width: 90% !important;}
.table .w95 {width: 95% !important;}
