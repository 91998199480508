// Heading
.ttl1 {
	display: block;
	margin-right: auto;
	margin-left: auto;
	padding: .5em;
	border: solid 3px $brand-main;
	color: $brand-main;
	font-weight: bold;
	font-size: 2rem;
	line-height: 1.4;
	@include breakpoint(md) {
		font-size: 3rem;
		text-align: center;
	}
	& + * {
		margin-top: 3rem;
		@include breakpoint(md) {
			margin-top: 5rem;
		}
	}
}

.ttl2 {
	display: inline-block;
	margin-right: auto;
	margin-left: auto;
	color: $black;
	font-weight: bold;
	font-size: 2rem;
	line-height: 1.4;
	text-align: center;
	@include breakpoint(md) {
		font-size: 2.5rem;
	}
	& + * {
		margin-top: 1em;
		@include breakpoint(md) {
			margin-top: 2em;
		}
	}
}


