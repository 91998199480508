@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var,
b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section,
summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}

body {
	line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

nav ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

a {
	margin: 0;
	padding: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}

ins {
	background-color: #ff9;
	color: #000;
	text-decoration: none;
}

mark {
	background-color: #ff9;
	color: #000;
	font-style: italic;
	font-weight: bold;
}

del {
	text-decoration: line-through;
}

abbr[title], dfn[title] {
	border-bottom: 1px dotted;
	cursor: help;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #cccccc;
	margin: 0;
	padding: 0;
}

input, select {
	vertical-align: baseline;
}

* {
	-webkit-text-size-adjust: 100%;
	box-sizing: border-box;
}

button,
select,
input[type="submit"] {
	cursor: pointer;
	-webkit-appearance: none;
}

ul {
	list-style: none;
}

ol {
	padding-left: 1.5em;
}

h1, h2, h3, h4, h5, h6 {
	font-weight: normal;
}

* {
	font-family: "Noto Sans JP", "Meiryo", "メイリオ", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Helvetica, sans-serif, "icons";
}

html {
	font-size: 62.5%;
}

body {
	background: #fff;
	color: #222;
	font-size: 1.6rem;
	line-height: 1.8;
	text-align: center;
}

@media screen and (min-width: 768px) {
	body {
		min-width: 102.4rem;
		line-height: 2;
	}
}

a:link,
a:visited {
	color: #ef7e00;
	text-decoration: underline;
}

a:hover,
a:active {
	color: #ef7e00;
	text-decoration: underline;
}

a:hover,
button:hover,
input[type="submit"]:hover,
input[type="button"]:hover {
	opacity: 0.6;
	transition: all .3s;
}

img {
	display: block;
	width: 100%;
}

.site_header {
	width: 100%;
	border-top: solid 5px #ef7e00;
	background: #fff;
}

@media screen and (min-width: 768px) {
	.site_header {
		position: fixed;
		top: 0;
		z-index: 50;
		height: 10rem;
		transition: all .3s;
	}
}

.site_header .header_body {
	position: relative;
	margin: auto;
}

@media screen and (min-width: 768px) {
	.site_header .header_body {
		width: 1000px;
		text-align: left;
	}
}

.site_header .site_logo {
	font-size: 0;
	line-height: 0;
	padding: 3rem 0;
	transition: all .3s;
}

@media screen and (min-width: 768px) {
	.site_header .site_logo {
		display: flex;
		align-items: center;
	}
}

.site_header .site_logo .logo_mark {
	display: inline-block;
	max-width: 20rem;
}

@media screen and (min-width: 768px) {
	.site_header .site_logo .logo_mark {
		width: 18rem;
		vertical-align: middle;
	}
}

.site_header .site_logo .site_subttl {
	display: block;
	margin-top: 1em;
	font-size: 1.8rem;
	line-height: 1.4;
}

@media screen and (min-width: 768px) {
	.site_header .site_logo .site_subttl {
		display: inline-block;
		position: relative;
		top: -.1rem;
		margin: 0 0 0 1.5em;
		font-size: 1.4rem;
	}
}

.site_header .btn_entry {
	display: none;
}

@media screen and (min-width: 768px) {
	.site_header .btn_entry {
		display: block;
		position: absolute;
		top: 2rem;
		right: 0;
	}
}

@media screen and (min-width: 768px) {
	.site_header.fixed {
		height: 8rem;
		border-top: solid 2px #ef7e00;
		box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.15);
		transition: all .3s;
	}
	.site_header.fixed .site_logo {
		padding-top: 2.1rem;
		transition: all .3s;
	}
	.site_header.fixed .btn_entry {
		top: 1.3rem;
		transition: all .3s;
	}
}

.page_ttl {
	background: #ef7e00;
	color: #fff;
	font-size: 2.5rem;
	line-height: 8rem;
	text-shadow: 0 0 0.7rem rgba(0, 0, 0, 0.3);
}

@media screen and (min-width: 768px) {
	.page_ttl {
		padding: 13rem 0 3rem;
		font-size: 4rem;
	}
}

.page_ttl .page_ttl_inner {
	position: relative;
	min-width: 100%;
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}

.page_ttl .page_ttl_inner::after {
	content: "";
	display: table;
	clear: both;
}

@media screen and (min-width: 768px) {
	.page_ttl .page_ttl_inner {
		min-width: 1000px;
		max-width: 1260px;
		padding-left: 3rem;
		padding-right: 3rem;
	}
}

@media screen and (min-width: 768px) {
	.page_ttl .page_ttl_inner {
		text-align: left;
	}
}

.page_ttl .page_name {
	font-weight: 500;
}

.site_footer {
	margin-top: 5rem;
	padding-bottom: 8rem;
	background: #f2f2f2;
}

@media screen and (min-width: 768px) {
	.site_footer {
		margin-top: 8rem;
		padding-bottom: 2rem;
	}
}

.site_footer .profile_list th {
	background: #ddd;
}

.site_footer .site_list {
	display: flex;
}

.site_footer .site_list .site_item {
	margin: 2rem 0 0 0;
}

@media screen and (min-width: 768px) {
	.site_footer .site_list .site_item {
		width: 48%;
		margin: 0 0 0 4%;
	}
}

.site_footer .site_list .site_item:first-child {
	margin-top: 0;
}

@media screen and (min-width: 768px) {
	.site_footer .site_list .site_item:first-child {
		margin: 0;
	}
}

.site_footer .site_list .site_item a {
	display: block;
	color: #222;
	text-decoration: none;
}

.site_footer .site_list .site_item p {
	margin-top: 0.5em;
	font-weight: bold;
}

.site_footer .copy {
	display: block;
	padding-top: 2em;
	border-top: solid 1px #cccccc;
	font-size: 1.2rem;
}

@media screen and (min-width: 768px) {
	.site_footer .copy {
		padding-top: 2rem;
	}
}

.btn_entry_fixed {
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 500;
	padding: 1rem;
	width: 100%;
	box-shadow: 0 0 0.5rem 0.3rem rgba(0, 0, 0, 0.1);
	background: rgba(0, 0, 0, 0.6);
}

.btn_entry_fixed .btn_entry {
	min-width: 30rem;
	min-height: 5rem;
	font-size: 1.8rem;
}

@media screen and (min-width: 768px) {
	.btn_entry_fixed {
		display: none;
	}
}

@media screen and (max-width: 767px) {
	.sm_none {
		display: none;
	}
}

@media screen and (min-width: 768px) {
	.md_none {
		display: none;
	}
}

.sec_lv1 {
	margin-top: 4rem;
}

@media screen and (min-width: 768px) {
	.sec_lv1 {
		margin-top: 8rem;
	}
}

.sec_lv2 {
	margin-top: 3rem;
}

@media screen and (min-width: 768px) {
	.sec_lv2 {
		margin-top: 6rem;
	}
}

.sec_lv3 {
	margin-top: 2rem;
}

@media screen and (min-width: 768px) {
	.sec_lv3 {
		margin-top: 4rem;
	}
}

.sec_lv4 {
	margin-top: 1.5rem;
}

.inner_base {
	padding-right: 1.5rem;
	padding-left: 1.5rem;
}

@media screen and (min-width: 768px) {
	.inner_base {
		width: 100rem;
		margin-right: auto;
		margin-left: auto;
		padding-right: 0;
		padding-left: 0;
	}
}

.inner_lv1 {
	padding-top: 4rem;
	padding-bottom: 4rem;
}

@media screen and (min-width: 768px) {
	.inner_lv1 {
		padding-top: 8rem;
		padding-bottom: 8rem;
	}
}

.inner_lv2 {
	padding-top: 3rem;
	padding-bottom: 3rem;
}

@media screen and (min-width: 768px) {
	.inner_lv2 {
		padding-top: 6rem;
		padding-bottom: 6rem;
	}
}

.inner_lv3 {
	padding-top: 2rem;
	padding-bottom: 2rem;
}

@media screen and (min-width: 768px) {
	.inner_lv3 {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}
}

.inner_lv4 {
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
}

.btn_block {
	background: #f2f2f2;
}

.ib {
	display: inline-block;
}

.lead_short {
	text-align: left;
}

@media screen and (min-width: 768px) {
	.lead_short {
		width: 70rem;
		margin-right: auto;
		margin-left: auto;
	}
}

.lead_centering {
	text-align: left;
}

@media screen and (min-width: 768px) {
	.lead_centering {
		text-align: center;
	}
}

/* Button
------------------------------------------------------------------- */
.btn {
	display: inline-block;
	position: relative;
	margin: auto;
	border: none;
	border-radius: 3px;
	transition: all .3s;
	outline: none;
	cursor: pointer;
	line-height: 1;
	text-align: center;
	text-decoration: none !important;
}

.btn[disabled] {
	background: #f2f2f2 !important;
	color: #cccccc !important;
}

.btn[disabled]:hover {
	opacity: 1;
	cursor: default;
}

/* Default */
.btn_default {
	background: #999999;
	color: #fff !important;
}

/* main */
.btn_main {
	background: #ef7e00;
	color: #fff !important;
}

/* Sub */
.btn_sub {
	background: #222;
	color: #fff !important;
}

/* Button Size */
.btn_xs {
	padding: .1rem .5rem;
	font-size: 1.2rem;
}

@media screen and (max-width: 767px) {
	.btn_xs {
		min-height: 3rem;
		padding: 1rem .5rem 0;
	}
}

.btn_sm {
	padding: .5rem 2em;
	font-size: 1.4rem;
}

@media screen and (max-width: 767px) {
	.btn_sm {
		min-height: 4rem;
		padding: 1.4rem 2em 0;
	}
}

.btn_md {
	padding: 1.8rem 2em;
}

@media screen and (max-width: 767px) {
	.btn_md {
		min-height: 4.5rem;
		padding: 1.6rem 3em;
	}
}

.btn_lg {
	padding: 2.2rem 2.5em;
	font-size: 2rem;
}

@media screen and (max-width: 767px) {
	.btn_lg {
		min-height: 4.5rem;
	}
}

.btn_block {
	display: block;
	width: 100%;
}

/* Option */
.btn_mail::before {
	content: "";
}

.btn_blank::after {
	margin-left: .5em;
	vertical-align: middle;
	font-size: 120%;
	content: "";
}

.btn_entry::before {
	margin-right: .5em;
	content: "";
}

.clr {
	clear: both;
}

.cf::after {
	content: "";
	display: table;
	clear: both;
}

.fl {
	float: left;
}

.fr {
	float: right;
}

ul.fbox,
ol.fbox {
	margin-left: 0;
}

ul.fbox li,
ol.fbox li {
	display: inline;
	list-style: none;
	float: left;
}

.form_layout {
	text-align: left;
}

.form_layout .required {
	display: inline-block;
	position: relative;
	top: -.2rem;
	margin-left: 1em;
	padding: .4rem 1em;
	background: #f18630;
	color: #fff;
	font-size: 1rem;
	line-height: 1;
	text-align: center;
}

.form_set .form_group {
	border-top: solid 1px #ef7e00;
}

.form_set .form_group_ttl {
	position: relative;
	margin-top: -.1rem;
	padding: 2rem 0 0 0;
	color: #ef7e00;
	font-size: 2.2rem;
	line-height: 1;
}

@media screen and (min-width: 768px) {
	.form_set .form_group_ttl {
		padding: 2rem 0 1rem 0;
	}
}

.form_set .form_row {
	display: block;
	width: 100%;
	padding: 0;
}

@media screen and (min-width: 768px) {
	.form_set .form_row {
		display: table;
	}
}

.form_set .form_row .unit {
	display: block;
	margin-top: 2rem;
}

@media screen and (min-width: 768px) {
	.form_set .form_row .unit {
		display: table-cell;
		width: 50%;
		padding: 2rem 2rem 0 2rem;
		vertical-align: top;
	}
}

.form_set .form_row > .unit:first-child {
	padding-left: 0;
}

.form_set .form_row > .unit:last-child {
	padding-right: 0;
}

.form_set .form_row .unit_ttl {
	position: relative;
}

.form_set .form_row .unit_con {
	margin-top: .5rem;
}

.form_set .form_row .unit_con > label {
	display: inline-block;
	margin-right: 1em;
}

.form_set .example {
	color: #999999;
}

input[type=text],
input[type=tel],
input[type=email],
select,
textarea {
	width: 100%;
	border: solid 1px #cccccc;
	background: #ffebd6;
	font-size: 1.6rem;
	outline: none;
}

input[type=text],
input[type=tel],
input[type=email],
select {
	max-height: 4.5rem;
}

input[type=text]:focus,
input[type=tel]:focus,
input[type=email]:focus,
input[type=password]:focus,
textarea:focus,
select:focus {
	border: solid 1px #ef7e00;
	box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
	background: #fff;
	transition: all .3s;
}

input[type=text],
input[type=tel],
input[type=email],
input[type=password] {
	padding: 1.3rem 1.1rem;
}

select {
	padding: 1rem 1.1rem;
}

input[type=radio],
input[type=checkbox] {
	margin-right: .3rem;
}

.input_short {
	width: 18rem;
}

.input_middle {
	width: 30rem;
}

.input_long {
	width: 100%;
}

.comment1 {
	max-width: 100% !important;
	height: 25rem;
	padding: 1rem;
	line-height: 1.8;
}

.select {
	position: relative;
	width: 100%;
	height: 4.6rem;
	padding: 0;
	border: 1px solid #cccccc;
}

.select::after {
	position: absolute;
	right: 1rem;
	top: 50%;
	z-index: 15;
	width: 0;
	height: 0;
	margin-top: -.3rem;
	border-style: solid;
	border-width: .7rem .4rem 0 .4rem;
	border-color: #222 transparent transparent transparent;
	content: "";
}

.select select {
	width: 100%;
	height: 4.4rem;
	border: none;
	padding: 0 1em;
	-webkit-appearance: none;
	font-size: 1.6rem;
}

.select select::-ms-expand {
	display: none;
}

input.error,
select.error,
textarea.error {
	font-weight: normal;
	border: solid 1px #FA4563;
	background: #FFF2F4;
}

p.error {
	padding-top: .3rem;
	color: #E6243F;
}

p.error:before {
	margin-right: .3rem;
	line-height: 1;
	content: "";
}

.submit {
	margin-top: .4rem;
	text-align: center;
}

@media screen and (min-width: 768px) {
	.submit {
		margin-top: 5rem;
	}
}

@media screen and (min-width: 768px) {
	.submit .btn_item {
		display: inline-block;
		position: relative;
		padding: 0 1rem;
		text-align: center;
	}
	.submit .btn_item .ajax-loader {
		position: absolute !important;
		bottom: -2.5rem !important;
		left: 50% !important;
		transform: translateX(-50%) !important;
		margin-left: 0 !important;
	}
}

.submit .btn_item input {
	margin-top: 1rem;
}

@media screen and (min-width: 768px) {
	.submit .btn_item input {
		margin-top: 0;
	}
}

.submit .btn_item input:first-child {
	margin-top: 0;
}

.submit .btn {
	width: 100%;
}

@media screen and (min-width: 768px) {
	.submit .btn {
		width: 25rem;
		margin: 0 1rem;
	}
}

@media screen and (min-width: 768px) {
	.done {
		padding: 3rem 0;
	}
}

.done .done_lead {
	color: #ef7e00;
	font-size: 2.5rem;
	line-height: 1.4;
}

@media screen and (min-width: 768px) {
	.done .done_lead {
		font-size: 3rem;
	}
}

.done .done_txt {
	margin-top: 1.5em;
	text-align: left;
}

@media screen and (min-width: 768px) {
	.done .done_txt {
		text-align: center;
	}
}

.done .home_back {
	margin-top: 3rem;
}

@media screen and (min-width: 768px) {
	.done .home_back {
		margin-top: 5rem;
	}
}

.ttl1 {
	display: block;
	margin-right: auto;
	margin-left: auto;
	padding: .5em;
	border: solid 3px #ef7e00;
	color: #ef7e00;
	font-weight: bold;
	font-size: 2rem;
	line-height: 1.4;
}

@media screen and (min-width: 768px) {
	.ttl1 {
		font-size: 3rem;
		text-align: center;
	}
}

.ttl1 + * {
	margin-top: 3rem;
}

@media screen and (min-width: 768px) {
	.ttl1 + * {
		margin-top: 5rem;
	}
}

.ttl2 {
	display: inline-block;
	margin-right: auto;
	margin-left: auto;
	color: #222;
	font-weight: bold;
	font-size: 2rem;
	line-height: 1.4;
	text-align: center;
}

@media screen and (min-width: 768px) {
	.ttl2 {
		font-size: 2.5rem;
	}
}

.ttl2 + * {
	margin-top: 1em;
}

@media screen and (min-width: 768px) {
	.ttl2 + * {
		margin-top: 2em;
	}
}

[class^="icon_"]::before,
[class*=" icon_"]::before {
	font-family: "icons";
	font-style: normal;
	font-weight: normal;
	speak: none;
	display: inline-block;
	text-decoration: inherit;
	width: 1em;
	margin-right: .2em;
	text-align: center;
	/* opacity: .8; */
	/* For safety - reset parent styles, that can break glyph codes*/
	font-variant: normal;
	text-transform: none;
	/* fix buttons height, for twitter bootstrap */
	line-height: 1em;
	/* Animation center compensation - margins should be symmetric */
	/* remove if not needed */
	margin-left: .2em;
	/* you can be more comfortable with increased icons size */
	/* font-size: 120%; */
	/* Font smoothing. That was taken from TWBS */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/* Uncomment for 3D effect */
	/* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon_mail::before {
	content: "\e82a";
}

.icon_text::before {
	content: "\f15c";
}

.icon_down-open1::before {
	content: "\e804";
}

.icon_left-open1::before {
	content: "\e805";
}

.icon_right-open1::before {
	content: "\e806";
}

.icon_up-open1::before {
	content: "\e807";
}

.icon_map::before {
	content: "\e808";
}

.icon_home::before {
	content: "\e810";
}

.icon_popup::before {
	content: "\e820";
}

.icon_attention::before {
	content: "\e812";
}

.icon_list::before {
	content: "\f009";
}

.icon_lock::before {
	content: "\e81c";
}

.icon_mkr-circle::before {
	content: "\e81d";
}

.icon_file-pdf::before {
	content: "\e817";
}

.icon_file-word::before {
	content: "\e81b";
}

.icon_file-excel::before {
	content: "\e81e";
}

.icon_phone::before {
	content: "\e829";
}

.icon_edit::before {
	content: "\e819";
}

ul.list_style_none,
ol.list_style_none {
	list-style-type: none;
	padding-left: 0;
}

ul[class^="list_disc"] > li {
	position: relative;
	padding-left: 1.5em;
}

ul[class^="list_disc"] > li::before {
	display: block;
	position: absolute;
	top: .6em;
	left: .1em;
	width: 1rem;
	height: 1rem;
	border-radius: 50%;
	content: "";
}

.list_disc > li::before {
	background: #999999;
}

.list_disc_primary > li::before {
	background: #ffa33d;
}

ul.list_free,
ol.list_free {
	list-style-type: none;
	padding-left: 0;
}

ul.list_free > li,
ol.list_free > li {
	padding-left: 1.5em;
	text-indent: -1.5em;
}

ol.list_decimal_brackets {
	margin-left: 0;
	padding-left: 0;
	counter-reset: cnt2;
}

ol.list_decimal_brackets > li {
	position: relative;
	list-style-type: none;
	padding-left: 2em;
}

ol.list_decimal_brackets > li::before {
	display: marker;
	position: absolute;
	top: 0;
	left: 0;
	content: "(" counter(cnt2) ") ";
	counter-increment: cnt2;
}

ul.list_block {
	list-style-type: none;
	padding-left: 0;
}

ul.list_block li {
	position: relative;
	margin-top: -.1rem;
	padding: 1.6rem 2.4rem 1.6rem 5rem;
	border: 1px solid #cccccc;
	background: #fff;
	line-height: 1.4;
}

ul.list_block li::before {
	display: block;
	position: absolute;
	top: calc(1.6rem + .3em);
	left: 2.4rem;
	width: 1rem;
	height: 1rem;
	border-radius: 50%;
	background: #ffa33d;
	content: "";
}

.dl_list_free > dt {
	margin-bottom: 0.5rem;
}

.dl_list_free > dt:not(:first-of-type) {
	margin-top: 0.5em;
}

.dl_list_free > dd {
	padding-left: 1.5em;
	text-indent: -1.5em;
}

.dl_list_disc dt,
.dl_list_disc_primary dt {
	padding-bottom: .5em;
	font-weight: bold;
}

.dl_list_disc > dd,
.dl_list_disc_primary > dd {
	position: relative;
	padding-left: 1.2em;
}

.dl_list_disc > dd::before,
.dl_list_disc_primary > dd::before {
	display: block;
	position: absolute;
	top: .5em;
	left: 0;
	width: 1rem;
	height: 1rem;
	border-radius: 50%;
	content: "";
}

.dl_list_disc > dd::before {
	background: #cccccc;
}

.dl_list_disc_primary > dd::before {
	background: #ffa33d;
}

.mt0 {
	margin-top: 0 !important;
}

.mt5 {
	margin-top: .5rem !important;
}

.mt10 {
	margin-top: 1rem !important;
}

.mt15 {
	margin-top: 1.5rem !important;
}

.mt20 {
	margin-top: 2rem !important;
}

.mt25 {
	margin-top: 2.5rem !important;
}

.mt30 {
	margin-top: 3rem !important;
}

.mt35 {
	margin-top: 3.5rem !important;
}

.mt40 {
	margin-top: 4rem !important;
}

.mt45 {
	margin-top: 4.5rem !important;
}

.mt50 {
	margin-top: 5rem !important;
}

.mt55 {
	margin-top: 5.5rem !important;
}

.mt60 {
	margin-top: 6rem !important;
}

.mt65 {
	margin-top: 6.5rem !important;
}

.mt70 {
	margin-top: 7rem !important;
}

.mt75 {
	margin-top: 7.5rem !important;
}

.mt80 {
	margin-top: 8rem !important;
}

.mt85 {
	margin-top: 8.5rem !important;
}

.mt90 {
	margin-top: 9rem !important;
}

.mt95 {
	margin-top: 9.5rem !important;
}

.mt100 {
	margin-top: 10rem !important;
}

.remodal-overlay {
	background-color: rgba(239, 126, 2, 0.8);
	background: -moz-linear-gradient(left, rgba(251, 177, 0, 0.85) 0%, rgba(239, 126, 2, 0.85) 100%);
	background: -webkit-linear-gradient(left, rgba(251, 177, 0, 0.85) 0%, rgba(239, 126, 2, 0.85) 100%);
	background: linear-gradient(to right, rgba(251, 177, 0, 0.85) 0%, rgba(239, 126, 2, 0.85) 100%);
}

@media screen and (max-width: 767px) {
	.remodal-wrapper {
		padding: 1.5rem;
	}
}

@media screen and (min-width: 768px) {
	.remodal-wrapper {
		padding: 0 5rem;
	}
}

.close_window {
	display: block;
	position: relative;
}

@media screen and (max-width: 767px) {
	.close_window {
		height: 3.5rem;
	}
}

@media screen and (min-width: 768px) {
	.close_window {
		height: 10rem;
	}
}

.close_window .close {
	font-size: 0;
	line-height: 0;
	display: inline-block;
	position: absolute;
	right: 0;
	text-indent: -99999px;
}

@media screen and (max-width: 767px) {
	.close_window .close {
		top: 0;
		width: 3rem;
		height: 3rem;
	}
}

@media screen and (min-width: 768px) {
	.close_window .close {
		top: 2.7rem;
		width: 4.5rem;
		height: 4.5rem;
	}
}

.close_window .close::before, .close_window .close::after {
	position: absolute;
	top: 50%;
	left: 0;
	height: .1rem;
	background: #fff;
	content: "";
}

@media screen and (max-width: 767px) {
	.close_window .close::before, .close_window .close::after {
		width: 3.5rem;
	}
}

@media screen and (min-width: 768px) {
	.close_window .close::before, .close_window .close::after {
		width: 5rem;
	}
}

.close_window .close::before {
	transform: rotate(45deg);
}

.close_window .close::after {
	transform: rotate(-45deg);
}

.remodal,
.remodal-wrapper::after {
	vertical-align: middle;
}

.modal_block {
	box-shadow: 0 0 1.5rem 0.2rem rgba(239, 126, 2, 0.1);
	background: #fff;
	text-align: left;
}

ul.note {
	list-style: none;
	margin-left: 0;
}

p.note,
ul.note li {
	margin-left: 1.5em;
	color: #666;
	font-size: 1.4rem;
	line-height: 1.8;
	text-align: left;
	text-indent: -1.5em;
}

li p.note {
	margin-top: 0 !important;
}

.table {
	width: 100%;
	border-style: solid;
	border-width: 1px 1px 0 0;
	border-color: #cccccc;
	table-layout: fixed;
}

@media screen and (min-width: 768px) {
	.table {
		border-width: 1px;
	}
}

.table th,
.table td {
	border-style: solid;
	border-width: 0 0 1px 1px;
	border-color: #cccccc;
	vertical-align: top;
	text-align: left;
	line-height: 1.6;
}

@media screen and (min-width: 768px) {
	.table th,
	.table td {
		border-width: 1px;
	}
}

.table thead th {
	background: #f2f2f2;
}

.table tbody th {
	background: #f2f2f2;
	font-weight: normal;
	text-align: left;
}

.table_thin thead th {
	padding: .8rem 0;
	font-size: 1.2rem;
}

.table_thin tbody th,
.table_thin tbody td {
	padding: 1rem 1em;
}

.table_regular thead th {
	padding: 1.5rem 0;
}

.table_regular tbody th,
.table_regular tbody td {
	display: block;
	padding: 1.5rem 1em 1.3rem;
}

@media screen and (min-width: 768px) {
	.table_regular tbody th,
	.table_regular tbody td {
		display: table-cell;
	}
}

.table_heavy thead th {
	padding: 1.5rem 0;
}

@media screen and (min-width: 768px) {
	.table_heavy thead th {
		padding: 2rem 0;
	}
}

.table_heavy tbody th,
.table_heavy tbody td {
	display: block;
	padding: 1.5rem 1em 1.3rem;
}

@media screen and (min-width: 768px) {
	.table_heavy tbody th,
	.table_heavy tbody td {
		padding: 2rem 1.5em 1.8rem;
		display: table-cell;
	}
}

@media screen and (min-width: 768px) {
	.table_th_sm {
		width: 10em;
	}
}

@media screen and (min-width: 768px) {
	.table_th_md {
		width: 15em;
	}
}

@media screen and (min-width: 768px) {
	.table_th_lg {
		width: 20em;
	}
}

/* Cell Width */
.table .w5 {
	width: 5% !important;
}

.table .w10 {
	width: 10% !important;
}

.table .w15 {
	width: 15% !important;
}

.table .w20 {
	width: 20% !important;
}

.table .w25 {
	width: 25% !important;
}

.table .w30 {
	width: 30% !important;
}

.table .w33 {
	width: 33% !important;
}

.table .w35 {
	width: 35% !important;
}

.table .w40 {
	width: 40% !important;
}

.table .w45 {
	width: 45% !important;
}

.table .w50 {
	width: 50% !important;
}

.table .w55 {
	width: 55% !important;
}

.table .w60 {
	width: 60% !important;
}

.table .w65 {
	width: 65% !important;
}

.table .w70 {
	width: 70% !important;
}

.table .w75 {
	width: 75% !important;
}

.table .w80 {
	width: 80% !important;
}

.table .w85 {
	width: 85% !important;
}

.table .w90 {
	width: 90% !important;
}

.table .w95 {
	width: 95% !important;
}

.tabs {
	display: flex;
	border-bottom: solid 1px #cccccc;
}

@media screen and (min-width: 768px) {
	.tabs {
		padding: 0 1.5rem;
	}
}

.tabs .tab_item {
	flex: 1;
	margin-bottom: -1px;
	border-style: solid;
	border-width: 1px 1px 1px 0;
	border-color: #cccccc;
	background: #f2f2f2;
}

.tabs .tab_item:first-child {
	border-width: 1px;
}

.tabs .tab_item.active {
	border-top: solid 2px #ef7e00;
	border-bottom-color: #fff;
	background: #fff;
	color: #ef7e00;
}

.tabs .tab_item.active a {
	color: #ef7e00;
	cursor: default;
}

.tabs .tab_item.active a:hover {
	opacity: 1;
}

.tabs .tab_item a {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	padding: 1em;
	color: #666;
	line-height: 1.4;
	text-decoration: none;
}

.fs0 {
	font-size: 0 !important;
	line-height: 0 !important;
}

.fs10 {
	font-size: 1rem !important;
	line-height: 1.5 !important;
}

.fs11 {
	font-size: 1.1rem !important;
	line-height: 1.5 !important;
}

.fs12 {
	font-size: 1.2rem !important;
	line-height: 1.5 !important;
}

.fs13 {
	font-size: 1.3rem !important;
	line-height: 1.5 !important;
}

.fs14 {
	font-size: 1.4rem !important;
	line-height: 1.5 !important;
}

.fs15 {
	font-size: 1.5rem !important;
	line-height: 1.5 !important;
}

.fs16 {
	font-size: 1.6rem !important;
	line-height: 1.5 !important;
}

.fs17 {
	font-size: 1.7rem !important;
	line-height: 1.5 !important;
}

.fs18 {
	font-size: 1.8rem !important;
	line-height: 1.5 !important;
}

.fs19 {
	font-size: 1.9rem !important;
	line-height: 1.5 !important;
}

.fs20 {
	font-size: 2rem !important;
	line-height: 1.4 !important;
}

.fs21 {
	font-size: 2.1rem !important;
	line-height: 1.4 !important;
}

.fs22 {
	font-size: 2.2rem !important;
	line-height: 1.4 !important;
}

.fs23 {
	font-size: 2.3rem !important;
	line-height: 1.4 !important;
}

.fs24 {
	font-size: 2.4rem !important;
	line-height: 1.4 !important;
}

.fs25 {
	font-size: 2.5rem !important;
	line-height: 1.4 !important;
}

.fs30 {
	font-size: 3rem !important;
	line-height: 1.4 !important;
}

.fs35 {
	font-size: 3.5rem !important;
	line-height: 1.4 !important;
}

.fs40 {
	font-size: 4rem !important;
	line-height: 1.4 !important;
}

.fwn {
	font-weight: normal !important;
}

.fwb {
	font-weight: bold !important;
}

.lh0 {
	line-height: 0 !important;
}

.lh12 {
	line-height: 1.2 !important;
}

.lh14 {
	line-height: 1.4 !important;
}

.lh16 {
	line-height: 1.6 !important;
}

.lh18 {
	line-height: 1.8 !important;
}

.lh20 {
	line-height: 2 !important;
}

.tl {
	text-align: left !important;
}

.tc {
	text-align: center !important;
}

.tr {
	text-align: right !important;
}

.vt {
	vertical-align: top !important;
}

.vm {
	vertical-align: middle !important;
}

.vl {
	vertical-align: baseline !important;
}

.vb {
	vertical-align: bottom !important;
}

.gray {
	color: #666 !important;
}

.red {
	color: #fffc01 !important;
}

.blue {
	color: #436ac4 !important;
}

.txt_point {
	color: #ef7e00 !important;
}

sup {
	vertical-align: top;
	font-size: 1.1rem;
	line-height: 1;
	color: #999999;
}

.txt {
	text-align: left;
}

@media screen and (min-width: 768px) {
	.txt {
		max-width: 70rem;
		margin-right: auto;
		margin-left: auto;
	}
}

.point_txt {
	color: #ef7e00;
}

@media screen and (min-width: 768px) {
	.entry .btn_entry {
		display: none;
	}
	.entry .site_header.fixed {
		height: 8rem;
		border-top: solid 2px #ef7e00;
		box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.6);
		transition: all .3s;
	}
	.entry .site_header.fixed .site_logo {
		padding-top: 2.1rem;
		transition: all .3s;
	}
	.entry .site_header.fixed .btn_entry {
		display: none;
	}
}

.entry .btn_entry_fixed {
	display: none;
}

.privacy_frame {
	width: 100%;
	height: 25rem;
	margin-top: 2rem;
	padding: 3rem;
	overflow: auto;
	border: solid 1px #ddd;
	background: #efefef;
	font-size: 1.4rem;
	line-height: 1.6;
}

.privacy_frame .privacy_block {
	margin-top: 1em;
}

.privacy_frame .privacy_ttl {
	padding-bottom: .2rem;
	font-weight: bold;
}

.privacy_frame .privacy_contact {
	margin-top: 1em;
}

.main_visual {
	position: relative;
	overflow: hidden;
	height: 30rem;
	color: #222;
}

@media screen and (min-width: 768px) {
	.main_visual {
		height: 60rem;
	}
}

.main_visual .visual_inner {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	padding: 2rem;
	background: -moz-linear-gradient(left, rgba(251, 177, 0, 0.85) 0%, rgba(239, 126, 2, 0.85) 100%);
	background: -webkit-linear-gradient(left, rgba(251, 177, 0, 0.85) 0%, rgba(239, 126, 2, 0.85) 100%);
	background: linear-gradient(to right, rgba(251, 177, 0, 0.85) 0%, rgba(239, 126, 2, 0.85) 100%);
}

@media screen and (min-width: 768px) {
	.main_visual .visual_inner {
		padding: 15rem 3rem 0;
	}
}

.main_visual .catch {
	margin-top: .5em;
	padding: 0 0 .5em .6em;
	border-bottom: solid 2px #222;
	font-weight: bold;
	font-size: 2.8rem;
	line-height: 1.4;
}

@media screen and (min-width: 768px) {
	.main_visual .catch {
		margin-top: 0;
		font-size: 5.5rem;
		letter-spacing: .2em;
	}
}

.main_visual .catch .point_txt {
	color: #fff;
}

.main_visual .sub_txt {
	display: inline-block;
	position: relative;
	margin-top: 1.5em;
	font-weight: bold;
	font-size: 1.4rem;
}

@media screen and (min-width: 768px) {
	.main_visual .sub_txt {
		font-size: 2rem;
		letter-spacing: .2em;
	}
}

.main_visual .visual_movie {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 0;
	transform: translate(-50%, -50%);
}

@media screen and (max-width: 767px) {
	.main_visual .visual_movie {
		min-width: 100%;
		min-height: 100%;
	}
}

@media screen and (min-width: 768px) {
	.main_visual .visual_movie {
		transform: translate(-50%, calc(-50% + 10rem));
		width: 100%;
	}
}

.about_company .lead {
	font-weight: bold;
	font-size: 2.5rem;
}

.about_company .lead + * {
	margin-top: 1.5em;
}

.movie_item {
	position: relative;
	padding: 56.25% 0 0 0;
}

.movie_item iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.movies_gallery_tmb {
	margin-top: 2rem;
}

@media screen and (min-width: 768px) {
	.movies_gallery_tmb {
		margin-top: 3rem;
	}
}

.movie_tmb_set {
	display: inline-flex !important;
	width: auto !important;
	margin: 0 -1rem !important;
}

.movie_tmb_item {
	opacity: 0.6;
	width: calc(33.333333% - 2rem) !important;
	max-width: 15rem;
	margin: 0 1rem;
	line-height: 1.5;
}

@media screen and (min-width: 768px) {
	.movie_tmb_item {
		width: 15rem;
	}
}

.movie_tmb_item img {
	border: 2px solid #fff;
}

.movie_tmb_item:hover {
	opacity: 0.8;
	cursor: pointer;
}

.movie_tmb_item.swiper-slide-thumb-active {
	opacity: 1;
	color: #ef7e00;
}

.movie_tmb_item.swiper-slide-thumb-active img {
	border-color: #ef7e00;
}

.movie_tmb_item.swiper-slide-thumb-active:hover {
	cursor: default;
}

.outline_movies .bx-wrapper {
	margin-bottom: 2rem;
	border: none;
	box-shadow: none;
}

@media screen and (min-width: 768px) {
	.outline_movies .bx-wrapper {
		margin-bottom: 4rem;
	}
}

.movie_slider_pager {
	display: flex;
	justify-content: space-between;
}

@media screen and (min-width: 768px) {
	.movie_slider_pager {
		justify-content: center;
	}
}

.movie_slider_pager a {
	width: 30%;
	color: #666;
	text-align: center;
	text-decoration: none;
}

@media screen and (max-width: 767px) {
	.movie_slider_pager a {
		font-size: 1.4rem;
	}
}

@media screen and (min-width: 768px) {
	.movie_slider_pager a {
		width: 15rem;
		margin: 0 1rem;
	}
}

.movie_slider_pager a img {
	display: block;
	opacity: 0.4;
	border: solid 1px #333;
}

.movie_slider_pager a.active {
	color: #ef7e00;
}

.movie_slider_pager a.active:hover {
	opacity: 1;
	cursor: default;
}

.movie_slider_pager a.active img {
	opacity: 1;
	border-color: #ef7e00;
}

.feature {
	padding-top: 22.5rem;
	background-color: #f2f2f2;
	background-image: url(../images/home/gallery_photo.jpg);
	background-size: 120rem 22.5rem;
	background-repeat: repeat-x;
	background-position: 0 0;
	animation: photo_slider 50s linear infinite;
}

@media screen and (min-width: 768px) {
	.feature {
		padding-top: 47rem;
		background-size: 240rem 45rem;
	}
}

@keyframes photo_slider {
	from {
		background-position: 0 0;
	}
	to {
		background-position: -240rem 0;
	}
}

.feature_gallery {
	display: flex;
	overflow: hidden;
}

@media screen and (max-width: 767px) {
	.feature_gallery {
		flex-direction: column;
	}
}

@media screen and (min-width: 768px) {
	.feature_gallery {
		margin-right: -1.6rem;
		margin-left: -1.6rem;
	}
}

.feature_gallery .gallery_item {
	display: block;
	margin: 0 auto;
	max-width: 40rem;
	font-size: 1.4rem;
}

@media screen and (max-width: 767px) {
	.feature_gallery .gallery_item:not(:first-of-type) {
		margin-top: 2rem;
	}
}

@media screen and (min-width: 768px) {
	.feature_gallery .gallery_item {
		width: 31.2rem;
		margin-right: 1.6rem;
		margin-left: 1.6rem;
	}
}

.feature_gallery .gallery_item figcaption {
	margin-top: 0.8em;
}

.member_interview {
	overflow-x: hidden;
	background: #ef7e02;
	/* Old browsers */
	background: -moz-linear-gradient(left, #fbb100 0%, #ef7e02 100%);
	background: -webkit-linear-gradient(left, #fbb100 0%, #ef7e02 100%);
	background: linear-gradient(to right, #fbb100 0%, #ef7e02 100%);
}

.member_interview .ttl1 {
	border-color: #fff;
	color: #fff;
}

@media screen and (min-width: 768px) {
	.member_list.swiper-container {
		padding-bottom: 4rem;
	}
}

.member_list.swiper-container .swiper-pagination {
	bottom: -.8rem !important;
}

.member_list.swiper-container .swiper-pagination-bullet {
	width: 3rem !important;
	height: .5rem !important;
	border-radius: 0 !important;
}

.member_list.swiper-container .swiper-pagination-bullet-active {
	background-color: #fff !important;
}

@media screen and (max-width: 767px) {
	.member_set {
		display: flex !important;
		flex-direction: row !important;
		justify-content: space-between !important;
		margin-top: -2.5rem !important;
	}
}

.member_item {
	width: calc(50% - 1rem) !important;
}

@media screen and (max-width: 767px) {
	.member_item {
		margin-top: 2.5rem !important;
	}
}

@media screen and (min-width: 768px) {
	.member_item {
		width: 22.6rem !important;
	}
}

a.member_card {
	display: block;
	color: #222;
	text-decoration: none;
}

a.member_card > span {
	display: block;
	font-weight: bold;
	text-align: center;
}

.member_card_tmb {
	border-radius: 50%;
}

.member_card_post {
	margin-top: 0.8em;
	font-size: 1.3rem;
}

.member_card_name {
	line-height: 1.2;
}

@media screen and (min-width: 768px) {
	.interview_area {
		width: 90rem;
	}
}

.interview_block {
	box-shadow: 0 0 1.5rem 0.2rem rgba(239, 126, 2, 0.1);
	background: #fff;
	text-align: left;
}

.interview_close {
	display: block;
	position: fixed;
	top: .5rem;
	right: .5rem;
	z-index: 5;
	width: 4rem;
	height: 4rem;
	padding: 0;
	border: none;
	background: transparent;
}

@media screen and (min-width: 768px) {
	.interview_close {
		top: 2rem;
		right: 3.5rem;
		width: 5rem;
		height: 5rem;
	}
}

.interview_close::before, .interview_close::after {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform-origin: center;
	width: 4rem;
	height: 0.1rem;
	background: #222;
	content: "";
}

@media screen and (min-width: 768px) {
	.interview_close::before, .interview_close::after {
		width: 7rem;
		background: #fff;
	}
}

.interview_close::before {
	transform: translateX(-50%) rotate(-45deg);
}

.interview_close::after {
	transform: translateX(-50%) rotate(45deg);
}

.interview_header {
	position: relative;
}

.interview_header_photo {
	position: relative;
	overflow: hidden;
}

@media screen and (max-width: 767px) {
	.interview_header_photo {
		width: 100%;
		padding-top: 75%;
	}
}

@media screen and (max-width: 767px) {
	.interview_header_photo > img {
		position: absolute;
		top: 0;
		right: 0;
		width: 200%;
	}
}

.interview_header_body {
	z-index: 1;
	padding: 2.5rem;
	background: #fff;
	font-weight: bold;
}

@media screen and (min-width: 768px) {
	.interview_header_body {
		position: absolute;
		top: 50%;
		left: -5rem;
		transform: translateY(-50%);
		width: 50rem;
		padding: 5rem;
	}
}

.interview_header_body .member_name {
	position: relative;
	padding-top: 1.5rem;
	font-size: 2.5rem;
	letter-spacing: .1em;
}

@media screen and (min-width: 768px) {
	.interview_header_body .member_name {
		padding-top: 2rem;
		font-size: 2rem;
	}
}

.interview_header_body .member_name::before {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 0.1rem;
	background: #cccccc;
	content: "";
}

.interview_header_body .member_post {
	color: #ef7e00;
	font-size: 1.2rem;
	letter-spacing: .05em;
}

.interview_header_catch {
	padding-bottom: 2.5rem;
	font-size: 1.8rem;
	line-height: 1.8;
}

@media screen and (min-width: 768px) {
	.interview_header_catch {
		padding-bottom: 3rem;
		font-size: 2.2rem;
	}
}

.member_profile {
	padding: 2.5rem;
	background-color: #ef7e00;
	color: #fff;
	font-size: 1.4rem;
}

@media screen and (min-width: 768px) {
	.member_profile {
		padding: 3rem 5rem 3.5rem;
	}
}

.member_profile_ttl {
	margin-bottom: .5em;
	color: #ffc789;
	font-size: 1.4rem;
	font-weight: bold;
	letter-spacing: .15em;
}

.interview_contents {
	padding: 0 2.5rem 5rem;
}

@media screen and (min-width: 768px) {
	.interview_contents {
		padding: 0 5rem 7rem 5rem;
	}
}

.interview_contents > section p:not(:first-of-type) {
	margin-top: 1.2em;
}

.interview_question {
	position: relative;
	margin-bottom: 3rem;
	padding-left: 3rem;
	color: #ef7e00;
	font-size: 1.4rem;
	font-weight: bold;
	letter-spacing: .15em;
}

.interview_question::before {
	display: block;
	position: absolute;
	top: 50%;
	left: 0;
	width: 2rem;
	height: 0.1rem;
	background: #ef7e00;
	content: "";
}

.tabs_job .tab_item a {
	font-size: 2rem;
}

.select_job {
	margin-top: 3rem;
}

.guideline_common {
	margin-top: -.1rem;
}
