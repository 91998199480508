.form_layout {
	text-align: left;
	.required {
		display: inline-block;
		position: relative;
		top: -.2rem;
		margin-left: 1em;
		padding: .4rem 1em;
		background: $warning-color;
		color: #fff;
		font-size: 1rem;
		line-height: 1;
		text-align: center;
	}
}
.form_set {
	.form_group {
		border-top: solid 1px $brand-main;
	}
	.form_group_ttl {
		position: relative;
		margin-top: -.1rem;
		padding: 2rem 0 0 0;
		color: $brand-main;
		font-size: 2.2rem;
		line-height: 1;
		@include breakpoint(md) {
			padding: 2rem 0 1rem 0;
		}
	}
	.form_row {
		display: block;
		width: 100%;
		padding: 0;
		@include breakpoint(md) {
			display: table;
		}
		.unit {
			display: block;
			margin-top: 2rem;
			@include breakpoint(md) {
				display: table-cell;
				width: 50%;
				padding: 2rem 2rem 0 2rem;
				vertical-align: top;
			}
		}
		& > .unit:first-child {padding-left: 0;}
		& > .unit:last-child {padding-right: 0;}
		.unit_ttl {
			position: relative;
		}
		.unit_con {
			margin-top: .5rem;
			& > label {
				display: inline-block;
				margin-right: 1em;
			}
		}
	}
	.example {
		color: $gray-light;
	}
}


// Input
//
input[type=text],
input[type=tel],
input[type=email],
select,
textarea {
	width: 100%;
	border: solid 1px $gray-lighter;
	background: $brand-main-lightest;
	font-size: 1.6rem;
	outline: none;
}

input[type=text],
input[type=tel],
input[type=email],
select {
	max-height: 4.5rem;
}

input[type=text]:focus,
input[type=tel]:focus,
input[type=email]:focus,
input[type=password]:focus,
textarea:focus,
select:focus {
	border: solid 1px $brand-main;
	box-shadow: 0 0 .5rem rgba(0, 0, 0, 0.2);
	background: #fff;
	transition: all .3s;
}

input[type=text],
input[type=tel],
input[type=email],
input[type=password] {
	padding: 1.3rem 1.1rem;
}

select {
	padding: 1rem 1.1rem;
}

input[type=radio],
input[type=checkbox] {
	margin-right: .3rem;
}

.input_short {width: 18rem;}
.input_middle {width: 30rem;}
.input_long {width: 100%;}

.comment1 {
	max-width: 100% !important;
	height: 25rem;
	padding: 1rem;
	line-height: 1.8;
}

.select {
	position: relative;
	width: 100%;
	height: 4.6rem;
	padding: 0;
	border: 1px solid $gray-lighter;
	&::after {
		position: absolute;
		right: 1rem;
		top: 50%;
		z-index: 15;
		width: 0;
		height: 0;
		margin-top: -.3rem;
		border-style: solid;
		border-width: .7rem .4rem 0 .4rem;
		border-color: $black transparent transparent transparent;
		content: "";
	}
	select {
		width: 100%;
		height: 4.4rem;
		border: none;
		padding: 0 1em;
		-webkit-appearance: none;
		font-size: 1.6rem;
	}
	select::-ms-expand {
		display: none;
	}
}

// Error
input.error,
select.error,
textarea.error {
	font-weight: normal;
	border: solid 1px #FA4563;
	background: #FFF2F4;
}

p.error {
	padding-top: .3rem;
	color: #E6243F;
	&:before {
		margin-right: .3rem;
		line-height: 1;
		content: $icon-attention;
	}
}

// Submit
//
.submit {
	margin-top: .4rem;
	text-align: center;
	@include breakpoint(md) {
		margin-top: 5rem;
	}
	.btn_item {
		@include breakpoint(md) {
			display: inline-block;
			position: relative;
			padding: 0 1rem;
			text-align: center;
			.ajax-loader {
				position: absolute !important;
				bottom: -2.5rem !important;
				left: 50% !important;
				transform: translateX(-50%) !important;
				margin-left: 0 !important;
			}
		}
		input {
			margin-top: 1rem;
			@include breakpoint(md) {
				margin-top: 0;
			}
			&:first-child {
				margin-top: 0;
			}
		}
	}
	.btn {
		width: 100%;
		@include breakpoint(md) {
			width: 25rem;
			margin: 0 1rem;
		}
	}
}

// Done
//
.done {
	@include breakpoint(md) {
		padding: 3rem 0;
	}
	.done_lead {
		color: $brand-main;
		font-size: 2.5rem;
		line-height: 1.4;
		@include breakpoint(md) {
			font-size: 3rem;
		}
	}
	.done_txt {
		margin-top: 1.5em;
		text-align: left;
		@include breakpoint(md) {
			text-align: center;
		}
	}
	.home_back {
		margin-top: 3rem;
		@include breakpoint(md) {
			margin-top: 5rem;
		}
	}
}

