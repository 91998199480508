$icon_mail : "\e82a";
$icon_text : "\f15c";
$icon_down-open1 : "\e804";
$icon_left-open1 : "\e805";
$icon_right-open1 : "\e806";
$icon_up-open1 : "\e807";
$icon_map : "\e808";
$icon_home : "\e810";
$icon_popup : "\e820";
$icon_attention : "\e812";
$icon_list : "\f009";
$icon_lock : "\e81c";
$icon_mkr-circle : "\e81d";
$icon_file-pdf : "\e817";
$icon_file-word : "\e81b";
$icon_file-excel : "\e81e";
$icon_phone : "\e829";
$icon_edit : "\e819";