// Note
ul.note {
	list-style: none;
	margin-left: 0;
}

p.note,
ul.note li {
	margin-left: 1.5em;
	color: $gray;
	font-size: 1.4rem;
	line-height: 1.8;
	text-align: left;
	text-indent: -1.5em;
}

li p.note {
	margin-top: 0 !important;
}
