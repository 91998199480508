.remodal-overlay {;
	background-color: rgba(239, 126, 2, 0.8);
	background: -moz-linear-gradient(left,  rgba(251,177,0,0.85) 0%, rgba(239,126,2,0.85) 100%);
	background: -webkit-linear-gradient(left,  rgba(251,177,0,0.85) 0%,rgba(239,126,2,0.85) 100%);
	background: linear-gradient(to right,  rgba(251,177,0,0.85) 0%,rgba(239,126,2,0.85) 100%);
}

.remodal-wrapper {
	@include breakpoint(sm) {
		padding: 1.5rem;
	}
	@include breakpoint(md) {
		padding: 0 5rem;
	}
}

.close_window {
	display: block;
	position: relative;
	@include breakpoint(sm) {
		height: 3.5rem;
	}
	@include breakpoint(md) {
		height: 10rem;
	}
	.close {
		@include font_zero;
		display: inline-block;
		position: absolute;
		right: 0;
		text-indent: -99999px;
		@include breakpoint(sm) {
			top: 0;
			width: 3rem;
			height: 3rem;
		}
		@include breakpoint(md) {
			top: 2.7rem;
			width: 4.5rem;
			height: 4.5rem;
		}
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			left: 0;
			height: .1rem;
			background: #fff;
			content: "";
			@include breakpoint(sm) {
				width: 3.5rem;
			}
			@include breakpoint(md) {
				width: 5rem;
			}
		}
		&::before {
			transform: rotate(45deg);
		}
		&::after {
			transform: rotate(-45deg);
		}
	}
}
.remodal,
.remodal-wrapper::after {
	vertical-align: middle;
}
.modal_block {
	box-shadow: 0 0 1.5rem .2rem rgba(239, 126, 2, 0.1);
	background: #fff;
	text-align: left;
}
