.icon_mail::before {content: "\e82a";}
.icon_text::before {content: "\f15c";}
.icon_down-open1::before {content: "\e804";}
.icon_left-open1::before {content: "\e805";}
.icon_right-open1::before {content: "\e806";}
.icon_up-open1::before {content: "\e807";}
.icon_map::before {content: "\e808";}
.icon_home::before {content: "\e810";}
.icon_popup::before {content: "\e820";}
.icon_attention::before {content: "\e812";}
.icon_list::before {content: "\f009";}
.icon_lock::before {content: "\e81c";}
.icon_mkr-circle::before {content: "\e81d";}
.icon_file-pdf::before {content: "\e817";}
.icon_file-word::before {content: "\e81b";}
.icon_file-excel::before {content: "\e81e";}
.icon_phone::before {content: "\e829";}
.icon_edit::before {content: "\e819";}