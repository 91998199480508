.entry {
	@include breakpoint(md) {
		.btn_entry {
			display: none;
		}
		.site_header.fixed {
			height: 8rem;
			border-top: solid 2px $brand-main;
			box-shadow: 0 0 .5rem rgba(0, 0, 0, 0.6);
			transition: all .3s;
			.site_logo {
				padding-top: 2.1rem;
				transition: all .3s;
			}
			.btn_entry {
				display: none;
			}
		}
	}
	.btn_entry_fixed {
		display: none;
	}
}
.privacy_frame {
	width: 100%;
	height: 25rem;
	margin-top: 2rem;
	padding: 3rem;
	overflow: auto;
	border: solid 1px #ddd;
	background: #efefef;
	font-size: 1.4rem;
	line-height: 1.6;
	.privacy_block {
		margin-top: 1em;
	}
	.privacy_ttl {
		padding-bottom: .2rem;
		font-weight: bold;
	}
	.privacy_contact {
		margin-top: 1em;
	}
}
