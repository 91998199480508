// List Style : None
ul.list_style_none,
ol.list_style_none {
	@include list_style_none;
}

// List Style : Disc
ul[class^="list_disc"] > li {
	position: relative;
	padding-left: 1.5em;
	&::before {
		display: block;
		position: absolute;
		top: .6em;
		left: .1em;
		width: 1rem;
		height: 1rem;
		border-radius: 50%;
		content: "";
	}
}
.list_disc > li::before {
	background: $gray-light;
}
.list_disc_primary > li::before {
	background: $brand-main-light;
}

// Line Style : Free
ul.list_free,
ol.list_free {
	@include list_style_none;
	> li {
		padding-left: 1.5em;
		text-indent: -1.5em;
	}
}

// List Style : Decimal-brackets
ol.list_decimal_brackets {
	margin-left: 0;
	padding-left: 0;
	counter-reset: cnt2;
	> li {
		position: relative;
		list-style-type: none;
		padding-left: 2em;
		&::before {
			display: marker;
			position: absolute;
			top: 0;
			left: 0;
			content: "(" counter(cnt2) ") ";
			counter-increment: cnt2;
		}
	}
}

// List Style : Row Block
ul.list_block {
	@include list_style_none;
	li {
		position: relative;
		margin-top: -.1rem;
		padding: 1.6rem 2.4rem 1.6rem 5rem;
		border: 1px solid $border-color-base;
		background: $white;
		line-height: 1.4;
		&::before {
			display: block;
			position: absolute;
			top: calc(1.6rem + .3em);
			left: 2.4rem;
			width: 1rem;
			height: 1rem;
			border-radius: 50%;
			background: $brand-main-light;
			content: "";
		}
	}
}

// Definition List
.dl_list_free {
	> dt {
		margin-bottom: 0.5rem;
		&:not(:first-of-type) {
			margin-top: 0.5em;
		}
	}
	> dd {
		padding-left: 1.5em;
		text-indent: -1.5em;
	}
}

.dl_list_disc,
.dl_list_disc_primary {
	dt {
		padding-bottom: .5em;
		font-weight: bold;
	}
	> dd {
		position: relative;
		padding-left: 1.2em;
		&::before {
			display: block;
			position: absolute;
			top: .5em;
			left: 0;
			width: 1rem;
			height: 1rem;
			border-radius: 50%;
			content: "";
		}
	}
}

.dl_list_disc > dd::before {
	background: $gray-lighter;
}
.dl_list_disc_primary > dd::before {
	background: $brand-main-light;
}
