// Visual
// ===============
.main_visual {
	position: relative;
	overflow: hidden;
	height: 30rem;
	color: $black;
	@include breakpoint(md) {
		height: 60rem;
	}
	.visual_inner {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		padding: 2rem;
		background: -moz-linear-gradient(left,  rgba(251,177,0,0.85) 0%, rgba(239,126,2,0.85) 100%);
		background: -webkit-linear-gradient(left,  rgba(251,177,0,0.85) 0%,rgba(239,126,2,0.85) 100%);
		background: linear-gradient(to right,  rgba(251,177,0,0.85) 0%,rgba(239,126,2,0.85) 100%);
		@include breakpoint(md) {
			padding: 15rem 3rem 0;
		}
	}
	.catch {
		margin-top: .5em;
		padding: 0 0 .5em .6em;
		border-bottom: solid 2px $black;
		font-weight: bold;
		font-size: 2.8rem;
		line-height: 1.4;
		@include breakpoint(md) {
			margin-top: 0;
			font-size: 5.5rem;
			letter-spacing: .2em;
		}
		.point_txt {
			color: #fff;
		}
	}
	.sub_txt {
		display: inline-block;
		position: relative;
		margin-top: 1.5em;
		font-weight: bold;
		font-size: 1.4rem;
		@include breakpoint(md) {
			font-size: 2rem;
			letter-spacing: .2em;
		}
	}
	.visual_movie {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 0;
		transform: translate(-50%, -50%);
		@include breakpoint(sm) {
			min-width: 100%;
			min-height: 100%;
		}
		@include breakpoint(md) {
			transform: translate(-50%, calc(-50% + 10rem));
			width: 100%;
		}
	}
}

// About
// ===============
// Lead
.about_company {
	.lead {
		font-weight: bold;
		font-size: 2.5rem;
		& + * {
			margin-top: 1.5em;
		}
	}
}

// Outline Movies
// ===============
.movie_item {
	position: relative;
	padding: 56.25% 0 0 0;
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
.movies_gallery_tmb {
	margin-top: 2rem;
	@include breakpoint(md) {
		margin-top: 3rem;
	}
}
.movie_tmb_set {
	display: inline-flex !important;
	width: auto !important;
	margin: 0 -1rem !important;
}
.movie_tmb_item {
	opacity: 0.6;
	width: calc(33.333333% - 2rem) !important;
	max-width: 15rem;
	margin: 0 1rem;
	line-height: 1.5;
	@include breakpoint(md) {
		width: 15rem;
	}
	img {
		border: 2px solid #fff;
	}
	&:hover {
		opacity: 0.8;
		cursor: pointer;
	}
	&.swiper-slide-thumb-active {
		opacity: 1;
		color: $brand-main;
		img {
			border-color: $brand-main;
		}
		&:hover {
			cursor: default;
		}
	}
}

.outline_movies {
	.bx-wrapper {
		margin-bottom: 2rem;
		border: none;
		box-shadow: none;
		@include breakpoint(md) {
			margin-bottom: 4rem;
		}
	}
}
.movie_slider_pager {
	display: flex;
	justify-content: space-between;
	@include breakpoint(md) {
		justify-content: center;
	}
	a {
		width: 30%;
		color: #666;
		text-align: center;
		text-decoration: none;
		@include breakpoint(sm) {
			font-size: 1.4rem;
		}
		@include breakpoint(md) {
			width: 15rem;
			margin: 0 1rem;
		}
		img {
			display: block;
			opacity: 0.4;
			border: solid 1px #333;
		}
		&.active {
			color: $brand-main;
			&:hover {
				opacity: 1;
				cursor: default;
			}
			img {
				opacity: 1;
				border-color: $brand-main;
			}
		}
	}
}

// Feature
// ===============
.feature {
	padding-top: 22.5rem;
	background-color: $gray-lightest;
	background-image: url(../images/home/gallery_photo.jpg);
	background-size: 120rem 22.5rem;
	background-repeat: repeat-x;
	background-position: 0 0;
	animation: photo_slider 50s linear infinite;
	@include breakpoint(md) {
		padding-top: 47rem;
		background-size: 240rem 45rem;
	}
}
@keyframes photo_slider {
	from { background-position: 0 0; }
	to { background-position: -240rem 0; }
}
.feature_gallery {
	display: flex;
	overflow: hidden;
	@include breakpoint(sm) {
		flex-direction: column;
	}
	@include breakpoint(md) {
		margin-right: -1.6rem;
		margin-left: -1.6rem;
	}
	.gallery_item {
		display: block;
		margin: 0 auto;
		max-width: 40rem;
		font-size: 1.4rem;
		@include breakpoint(sm) {
			&:not(:first-of-type) {
				margin-top: 2rem;
			}
		}
		@include breakpoint(md) {
			width: 31.2rem;
			margin-right: 1.6rem;
			margin-left: 1.6rem;
		}
		figcaption {
			margin-top: 0.8em;
		}
	}
}

// Interview
// ===============
// Member List
.member_interview {
	overflow-x: hidden;
	background: rgb(239,126,2); /* Old browsers */
	background: -moz-linear-gradient(left,  rgba(251,177,0,1) 0%, rgba(239,126,2,1) 100%);
	background: -webkit-linear-gradient(left,  rgba(251,177,0,1) 0%,rgba(239,126,2,1) 100%);
	background: linear-gradient(to right,  rgba(251,177,0,1) 0%,rgba(239,126,2,1) 100%);
	.ttl1 {
		border-color: $white;
		color: $white;
	}
}
.member_list.swiper-container {
	@include breakpoint(md) {
		padding-bottom: 4rem;
	}
	.swiper-pagination {
		bottom: -.8rem !important;
	}
	.swiper-pagination-bullet {
		width: 3rem !important;
		height: .5rem !important;
		border-radius: 0 !important;
	}
	.swiper-pagination-bullet-active {
		background-color: $white !important;
	}
}
.member_set {
	@include breakpoint(sm) {
		display: flex !important;
		flex-direction: row !important;
		justify-content: space-between !important;
		margin-top: -2.5rem !important;
	}
}
.member_item {
	width: calc(50% - 1rem) !important;
	@include breakpoint(sm) {
		margin-top: 2.5rem !important;
	}
	@include breakpoint(md) {
		width: 22.6rem !important;
	}
}
a.member_card {
	display: block;
	color: $black;
	text-decoration: none;
	> span {
		display: block;
		font-weight: bold;
		text-align: center;
	}
}
.member_card_tmb {
	border-radius: 50%;
}
.member_card_post {
	margin-top: 0.8em;
	font-size: 1.3rem;
}
.member_card_name {
	line-height: 1.2;
}

// Interview Detail
.interview_area {
	@include breakpoint(md) {
		width: 90rem;
	}
}
.interview_block {
	box-shadow: 0 0 1.5rem .2rem rgba(239, 126, 2, 0.1);
	background: #fff;
	text-align: left;
}
.interview_close {
	display: block;
	position: fixed;
	top: .5rem;
	right: .5rem;
	z-index: 5;
	width: 4rem;
	height: 4rem;
	padding: 0;
	border: none;
	background: transparent;
	@include breakpoint(md) {
		top: 2rem;
		right: 3.5rem;
		width: 5rem;
		height: 5rem;
	}
	&::before,
	&::after {
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform-origin: center;
		width: 4rem;
		height: 0.1rem;
		background: $black;
		content: "";
		@include breakpoint(md) {
			width: 7rem;
			background: $white;
		}
	}
	&::before {transform: translateX(-50%) rotate(-45deg);}
	&::after {transform: translateX(-50%) rotate(45deg);}
}
.interview_header {
	position: relative;
}
.interview_header_photo {
	position: relative;
	overflow: hidden;
	@include breakpoint(sm) {
		width: 100%;
		padding-top: 75%;
	}
	> img {
		@include breakpoint(sm) {
			position: absolute;
			top: 0;
			right: 0;
			width: 200%;
		}
	}
}
.interview_header_body {
	z-index: 1;
	padding: 2.5rem;
	background: $white;
	font-weight: bold;
	@include breakpoint(md) {
		position: absolute;
		top: 50%;
		left: -5rem;
		transform: translateY(-50%);
		width: 50rem;
		padding: 5rem;
	}
	.member_name {
		position: relative;
		padding-top: 1.5rem;
		font-size: 2.5rem;
		letter-spacing: .1em;
		@include breakpoint(md) {
			padding-top: 2rem;
			font-size: 2rem;
		}
		&::before {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 0.1rem;
			background: $gray-lighter;
			content: "";
		}
	}
	.member_post {
		color: $brand-main;
		font-size: 1.2rem;
		letter-spacing: .05em;
	}
}
.interview_header_catch {
	padding-bottom: 2.5rem;
	font-size: 1.8rem;
	line-height: 1.8;
	@include breakpoint(md) {
		padding-bottom: 3rem;
		font-size: 2.2rem;
	}
}
.member_profile {
	padding: 2.5rem;
	background-color: $brand-main;
	color: $white;
	font-size: 1.4rem;
	@include breakpoint(md) {
		padding: 3rem 5rem 3.5rem;
	}
}
.member_profile_ttl {
	margin-bottom: .5em;
	color: $brand-main-lighter;
	font-size: 1.4rem;
	font-weight: bold;
	letter-spacing: .15em;
}
.interview_contents {
	padding: 0 2.5rem 5rem;
	@include breakpoint(md) {
		padding: 0 5rem 7rem 5rem;
	}
	> section p:not(:first-of-type) {
		margin-top: 1.2em;
	}
}
.interview_question {
	position: relative;
	margin-bottom: 3rem;
	padding-left: 3rem;
	color: $brand-main;
	font-size: 1.4rem;
	font-weight: bold;
	letter-spacing: .15em;
	&::before {
		display: block;
		position: absolute;
		top: 50%;
		left: 0;
		width: 2rem;
		height: 0.1rem;
		background: $brand-main;
		content: "";
	}
}

// Outline
// ===============
.tabs_job .tab_item a {
	font-size: 2rem;
}
.select_job {
	margin-top: 3rem;
}

.guideline_common {
	margin-top: -.1rem;
}
