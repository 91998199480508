/* Button
------------------------------------------------------------------- */

.btn {
	display: inline-block;
	position: relative;
	margin: auto;
	border: none;
	border-radius: 3px;
	transition: all .3s;
	outline: none;
	cursor: pointer;
	line-height: 1;
	text-align: center;
	text-decoration: none !important;
	&[disabled] {
		background: $gray-lightest !important;
		color: $gray-lighter !important;
		&:hover {
			opacity: 1;
			cursor: default;
		}
	}
}

/* Default */

.btn_default {
	@include button_color(#fff, $gray-light)
}

/* main */

.btn_main {
	@include button_color(#fff, $brand-main)
}

/* Sub */

.btn_sub {
	@include button_color(#fff, $brand-accent1)
}

/* Button Size */

.btn_xs {
	padding: .1rem .5rem;
	font-size: 1.2rem;
	@include breakpoint(sm) {
		min-height: 3rem;
		padding: 1rem .5rem 0;
	}
}

.btn_sm {
	padding: .5rem 2em;
	font-size: 1.4rem;
	@include breakpoint(sm) {
		min-height: 4rem;
		padding: 1.4rem 2em 0;
	}
}

.btn_md {
	padding: 1.8rem 2em;
	@include breakpoint(sm) {
		min-height: 4.5rem;
		padding: 1.6rem 3em;
	}
}

.btn_lg {
	padding: 2.2rem 2.5em;
	font-size: 2rem;
	@include breakpoint(sm) {
		min-height: 4.5rem;
	}
}

.btn_block {
	display: block;
	width: 100%;
}

/* Option */

.btn_mail {
	&::before {
		content: $icon-mail;
	}
}


.btn_blank {
	&::after {
		margin-left: .5em;
		vertical-align: middle;
		font-size: 120%;
		content: $icon-popup;
	}
}

.btn_entry {
	&::before {
		margin-right: .5em;
		content: $icon-edit;
	}
}
