// Custom reset
* {
	-webkit-text-size-adjust: 100%;
	box-sizing: border-box;
}

button,
select,
input[type="submit"] {
	cursor: pointer;
	-webkit-appearance: none;
}

ul {
	list-style: none;
}

ol {
	padding-left: 1.5em;
}

h1, h2, h3, h4, h5, h6 {
	font-weight: normal;
}
