// Block Level
//
// Section Block

.sec_lv1 {
	margin-top: 4rem;
	@include breakpoint(md) {
		margin-top: 8rem;
	}
}

.sec_lv2 {
	margin-top: 3rem;
	@include breakpoint(md) {
		margin-top: 6rem;
	}
}

.sec_lv3 {
	margin-top: 2rem;
	@include breakpoint(md) {
		margin-top: 4rem;
	}
}

.sec_lv4 {
	margin-top: 1.5rem;
}

// Inner Block
.inner_base {
	padding-right: 1.5rem;
	padding-left: 1.5rem;
	@include breakpoint(md) {
		width: 100rem;
		margin-right: auto;
		margin-left: auto;
		padding-right: 0;
		padding-left: 0;
	}
}

.inner_lv1 {
	padding-top: 4rem;
	padding-bottom: 4rem;
	@include breakpoint(md) {
		padding-top: 8rem;
		padding-bottom: 8rem;
	}
}

.inner_lv2 {
	padding-top: 3rem;
	padding-bottom: 3rem;
	@include breakpoint(md) {
		padding-top: 6rem;
		padding-bottom: 6rem;
	}
}

.inner_lv3 {
	padding-top: 2rem;
	padding-bottom: 2rem;
	@include breakpoint(md) {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}
}

.inner_lv4 {
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
}

// Button Block
.btn_block {
	background: $gray-lightest;
}

// Inline Block
.ib {display: inline-block;}

// Lead Block
.lead_short {
	text-align: left;
	@include breakpoint(md) {
		width: 70rem;
		margin-right: auto;
		margin-left: auto;
	}
}

.lead_centering {
	text-align: left;
	@include breakpoint(md) {
		text-align: center;
	}
}
