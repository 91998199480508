// Header
// ==============================
.site_header {
	width: 100%;
	border-top: solid 5px $brand-main;
	background: #fff;
	@include breakpoint(md) {
		position: fixed;
		top: 0;
		z-index: 50;
		height: 10rem;
		transition: all .3s;
	}
	.header_body {
		position: relative;
		margin: auto;
		@include breakpoint(md) {
			width: $contents-width-base;
			text-align: left;
		}
	}
	.site_logo {
		@include font_zero;
		padding: 3rem 0;
		transition: all .3s;
		@include breakpoint(md) {
			display: flex;
			align-items: center;
		}
		.logo_mark {
			display: inline-block;
			max-width: 20rem;
			@include breakpoint(md) {
				width: 18rem;
				vertical-align: middle;
			}
		}
		.site_subttl {
			display: block;
			margin-top: 1em;
			font-size: 1.8rem;
			line-height: 1.4;
			@include breakpoint(md) {
				display: inline-block;
				position: relative;
				top: -.1rem;
				margin: 0 0 0 1.5em;
				font-size: 1.4rem;
			}
		}
	}
	.btn_entry {
		display: none;
		@include breakpoint(md) {
			display: block;
			position: absolute;
			top: 2rem;
			right: 0;
		}
	}
	@include breakpoint(md) {
		&.fixed {
			height: 8rem;
			border-top: solid 2px $brand-main;
			box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.15);
			transition: all .3s;
			.site_logo {
				padding-top: 2.1rem;
				transition: all .3s;
			}
			.btn_entry {
				top: 1.3rem;
				transition: all .3s;
			}
		}
	}
}


// Page Title
// ==============================

.page_ttl {
	background: $brand-main;
	color: #fff;
	font-size: 2.5rem;
	line-height: 8rem;
	text-shadow: 0 0 .7rem rgba(0, 0, 0, 0.3);
	@include breakpoint(md) {
		padding: 13rem 0 3rem;
		font-size: 4rem;
	}
	.page_ttl_inner {
		@include inner_block;
		@include breakpoint(md) {
			text-align: left;
		}
	}
	.page_name {
		font-weight: 500;
	}
}

// Footer
// ==============================
.site_footer {
	margin-top: 5rem;
	padding-bottom: 8rem;
	background: $gray-lightest;
	@include breakpoint(md) {
		margin-top: 8rem;
		padding-bottom: 2rem;
	}
	.profile_list {
		th {
			background: #ddd;
		}
	}
	.site_list {
		display: flex;
		.site_item {
			margin: 2rem 0 0 0;
			@include breakpoint(md) {
				width: 48%;
				margin:0 0 0 4%;
			}
			&:first-child {
				margin-top: 0;
				@include breakpoint(md) {
					margin: 0;
				}
			}
			a {
				display: block;
				color: $black;
				text-decoration: none;
			}
			p {
				margin-top: 0.5em;
				font-weight: bold;
			}
		}
	}
	.copy {
		display: block;
		padding-top: 2em;
		border-top: solid 1px $border-color-base;
		font-size: 1.2rem;
		@include breakpoint(md) {
			padding-top: 2rem;
		}
	}
}

// SPFooter Fixed Nav - Entry
.btn_entry_fixed {
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 500;
	padding: 1rem;
	width: 100%;
	box-shadow: 0 0 .5rem .3rem rgba(0, 0, 0, 0.1);
	background: rgba(0, 0, 0, 0.6);
	.btn_entry {
		min-width: 30rem;
		min-height: 5rem;
		font-size: 1.8rem;
	}
	@include breakpoint(md) {
		display: none;
	}
}

// Etc
// ==============================
// Display None

@include breakpoint(sm) {
	.sm_none {
		display: none;
	}
}

@include breakpoint(md) {
	.md_none {
		display: none;
	}
}

