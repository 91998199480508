// Font
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');

* {
	font-family: "Noto Sans JP", "Meiryo", "メイリオ", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Helvetica, sans-serif, "icons";
}

// HTML & Body
html {
	font-size: 62.5%;
}

body {
	background: $body-bg-color;
	color: $text-color-base;
	font-size: 1.6rem;
	line-height: 1.8;
	text-align: center;
	@include breakpoint(md) {
		min-width: 102.4rem;
		line-height: 2;
	}
}

// Anchor
a:link,
a:visited {
	color: $link-color;
	text-decoration: $link-decoration;
}

a:hover,
a:active {
	color: $link-hover-color;
	text-decoration: $link-hover-decoration;
}

// Alpha
a:hover,
button:hover,
input[type="submit"]:hover,
input[type="button"]:hover {
	opacity: 0.6;
	transition: all .3s;
}

// Img
img {
	display: block;
	width: 100%;
}

