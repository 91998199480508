// Margin
//
.mt0 {margin-top: 0 !important;}
.mt5 {margin-top: .5rem !important;}
.mt10 {margin-top: 1rem !important;}
.mt15 {margin-top: 1.5rem !important;}
.mt20 {margin-top: 2rem !important;}
.mt25 {margin-top: 2.5rem !important;}
.mt30 {margin-top: 3rem !important;}
.mt35 {margin-top: 3.5rem !important;}
.mt40 {margin-top: 4rem !important;}
.mt45 {margin-top: 4.5rem !important;}
.mt50 {margin-top: 5rem !important;}
.mt55 {margin-top: 5.5rem !important;}
.mt60 {margin-top: 6rem !important;}
.mt65 {margin-top: 6.5rem !important;}
.mt70 {margin-top: 7rem !important;}
.mt75 {margin-top: 7.5rem !important;}
.mt80 {margin-top: 8rem !important;}
.mt85 {margin-top: 8.5rem !important;}
.mt90 {margin-top: 9rem !important;}
.mt95 {margin-top: 9.5rem !important;}
.mt100 {margin-top: 10rem !important;}
