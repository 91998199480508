// Font
// Font Size
.fs0 {font-size: 0 !important;line-height: 0 !important;}
.fs10 {font-size: 1rem !important; line-height: 1.5 !important;}
.fs11 {font-size: 1.1rem !important; line-height: 1.5 !important;}
.fs12 {font-size: 1.2rem !important; line-height: 1.5 !important;}
.fs13 {font-size: 1.3rem !important; line-height: 1.5 !important;}
.fs14 {font-size: 1.4rem !important; line-height: 1.5 !important;}
.fs15 {font-size: 1.5rem !important; line-height: 1.5 !important;}
.fs16 {font-size: 1.6rem !important; line-height: 1.5 !important;}
.fs17 {font-size: 1.7rem !important; line-height: 1.5 !important;}
.fs18 {font-size: 1.8rem !important; line-height: 1.5 !important;}
.fs19 {font-size: 1.9rem !important; line-height: 1.5 !important;}
.fs20 {font-size: 2rem !important; line-height: 1.4 !important;}
.fs21 {font-size: 2.1rem !important; line-height: 1.4 !important;}
.fs22 {font-size: 2.2rem !important; line-height: 1.4 !important;}
.fs23 {font-size: 2.3rem !important; line-height: 1.4 !important;}
.fs24 {font-size: 2.4rem !important; line-height: 1.4 !important;}
.fs25 {font-size: 2.5rem !important; line-height: 1.4 !important;}
.fs30 {font-size: 3rem !important; line-height: 1.4 !important;}
.fs35 {font-size: 3.5rem !important; line-height: 1.4 !important;}
.fs40 {font-size: 4rem !important; line-height: 1.4 !important;}

// Font Weight
.fwn {font-weight: normal !important;}
.fwb {font-weight: bold !important;}

// Line Height
.lh0 {line-height: 0 !important;}
.lh12 {line-height: 1.2 !important;}
.lh14 {line-height: 1.4 !important;}
.lh16 {line-height: 1.6 !important;}
.lh18 {line-height: 1.8 !important;}
.lh20 {line-height: 2 !important;}

// Text align
.tl {text-align: left !important;}
.tc {text-align: center !important;}
.tr {text-align: right !important;}

// Vertical align
.vt {vertical-align: top !important;}
.vm {vertical-align: middle !important;}
.vl {vertical-align: baseline !important;}
.vb {vertical-align: bottom !important;}

// Font Color
.gray {color: $gray !important;}
.red {color: $brand-accent2 !important;}
.blue {color: #436ac4 !important;}
.txt_point {color: $brand-main !important;}

// Sup
sup {
	vertical-align: top;
	font-size: 1.1rem;
	line-height: 1;
	color: $gray-light;
}

.txt {
	text-align: left;
	@include breakpoint(md) {
		max-width: 70rem;
		margin-right: auto;
		margin-left: auto;
	}
}

.point_txt {
	color: $brand-main;
}
