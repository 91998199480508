.tabs {
	display: flex;
	border-bottom: solid 1px $border-color-base;
	@include breakpoint(md) {
		padding: 0 1.5rem;
	}
	.tab_item {
		flex: 1;
		margin-bottom: -1px;
		border-style: solid;
		border-width: 1px 1px 1px 0;
		border-color: $border-color-base;
		background: $gray-lightest;
		&:first-child {
			border-width: 1px;
		}
		&.active {
			border-top: solid 2px $brand-main;
			border-bottom-color: #fff;
			background: #fff;
			color: $brand-main;
			a {
				color: $brand-main;
				cursor: default;
				&:hover {
					opacity: 1;
				}
			}
		}
		a {
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			padding: 1em;
			color: $gray;
			line-height: 1.4;
			text-decoration: none;
		}
	}
}
