
// Brakepoint
@mixin breakpoint($breakpoint: small) {
	@media #{map-get($breakpoints, $breakpoint)} {
		@content;
	}
}

@mixin inner_block {
	position: relative;
	min-width: 100%;
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	@include clearfix;
	@include breakpoint(md) {
		min-width: $contents-width-min;
		max-width: $contents-width-max;
		padding-left: 3rem;
		padding-right: 3rem;
	}
}

// Font size zero
@mixin font_zero {
	font-size: 0;
	line-height: 0;
}

// Button Color
@mixin button_color($btn-txt-color, $btn-bg-color) {
	background: $btn-bg-color;
	color: $btn-txt-color !important;
}

// Clear fix
@mixin clearfix() {
	&::after {
		content: "";
		display: table;
		clear: both;
	}
}

// List Style None
@mixin list_style_none {
	list-style-type: none;
	padding-left: 0;
}

// icon
//
// icon position : right fix
@mixin icon-right-fixed($icon-name:"", $font-size:inherit, $icon-color:inherit, $position-right:1rem) {
	&:after{
		position: absolute;
		top: 50%;
		right: $position-right;
		margin-top: -.5em;
		color: $icon-color;
		font-size: $font-size;
		line-height: 1;
		content: $icon-name;
	}
}

// icon position : left fix
@mixin icon-left-fixed($icon-name:"", $font-size:inherit, $icon-color:inherit, $position-left:1rem) {
	&:after{
		position: absolute;
		top: 50%;
		left: $position-left;
		margin-top: -.5em;
		color: $icon-color;
		font-size: $font-size;
		line-height: 1;
		content: $icon-name;
	}
}

// icon mixin
@mixin icon-_base {
	display: inline-block;
	width: 1em;
	margin-right: .2em;
	font-variant: normal;
	font-weight: normal;
	font-style: normal;
	line-height: 1em;
	text-decoration: none;
	text-align: center;
	text-transform: none;
	speak: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// Image
//
// Radius Images
@mixin img_rounded($radius:.3rem) {
	@include font_zero;
	display: block;
	overflow: hidden;
	border-radius: $radius;
}

// Image Zoom Link
@mixin img_zoon_link {
	overflow: hidden;
	img {
		transition: all .3s ease-in-out;
	}
	&:hover img {
		transform: scale(1.2);
	}
}
