// Colors
//
// Base Colors

$white:                 #fff !default;
$black:                 #222 !default;
$gray:              #666 !default;
$red:               #e6243f !default;
$orange:            #f18630 !default;
$yellow:            #f2c204 !default;
$lemon:         #fffc01 !default;
$green:             #5cb85c !default;
$lightgreen:             #72ae37 !default;
$blue:              #1468af !default;
$teal:              #14afaf !default;
$pink:              #fb76a5 !default;
$purple:            #ba6adc !default;

// Brand Colors
// Base color
$brand-base:            #fff !default;

// Main color
$brand-main:            #ef7e00 !default;
$brand-main-light:      lighten($brand-main, 15%) !default;
$brand-main-lighter:        lighten($brand-main, 30%) !default;
$brand-main-lightest:       lighten($brand-main, 45%) !default;
$brand-main-dark:       darken($brand-main, 10%) !default;
$brand-main-darker:     darken($brand-main, 25%) !default;
$brand-main-darkest:        darken($brand-main, 35%) !default;

// Accent color
// Accent 1
$brand-accent1:     $black !default;
$brand-accent1-light:       lighten($brand-accent1, 20%) !default;
$brand-accent1-lighter:     lighten($brand-accent1, 40%) !default;
$brand-accent1-lightest:    lighten($brand-accent1, 60%) !default;
$brand-accent1-dark:        darken($brand-accent1, 5%) !default;
$brand-accent1-darker:      darken($brand-accent1, 15%) !default;
$brand-accent1-darkest: darken($brand-accent1, 25%) !default;

// Accent color
// Accent 2
$brand-accent2:     $lemon !default;
$brand-accent2-light:       lighten($brand-accent2, 20%) !default;
$brand-accent2-lighter:     lighten($brand-accent2, 30%) !default;
$brand-accent2-lightest:    lighten($brand-accent2, 40%) !default;
$brand-accent2-dark:        darken($brand-accent2, 10%) !default;
$brand-accent2-darker:      darken($brand-accent2, 20%) !default;
$brand-accent2-darkest: darken($brand-accent2, 30%) !default;

// Grayscale
$gray-light:            lighten($gray, 20%) !default;
$gray-lighter:          lighten($gray, 40%) !default;
$gray-lightest:         lighten($gray, 55%) !default;
//$gray-dark:           darken($gray, 10%) !default;
//$gray-darker:         darken($gray, 20%) !default;
//$gray-darkest:        darken($gray, 30%) !default;

// Text
$text-color-base:       $black !default;

// Links
$link-color:            $brand-main !default;
$link-decoration:       underline !default;
$link-hover-color:      $brand-main !default;
$link-hover-decoration:     underline !default;

// Border
$border-color-base:     $gray-lighter !default;

// Background
$body-bg-color:     $white !default;
$container-bg-color:        $brand-base !default;
$content-bg-color:      $brand-base !default;
$bg-color-contact:      $black !default;
$bg-color-request:      $yellow !default;
$bg-color-danger:       $brand-accent2-lightest !default;

// etc
$warning-color:     $orange !default;
$danger-color:          $red !default;

//-----------------------------------------------------------------------------------------
// Width
$contents-width-base:       1000px !default;
$contents-width-min:        1000px !default;
$contents-width-max:        1260px !default;
$contents-main-width:       712px !default;
$contents-sub-width:        216px !default;

//-----------------------------------------------------------------------------------------
// BrakePoint
$breakpoints: (
	'sm': 'screen and (max-width: 767px)',
	'md': 'screen and (min-width: 768px)',
	'lg': 'screen and (min-width: 1400px)',
) !default;
